<template>
    <div>
        <showAt breakpoint="large">
            <div class="q-section-block bg-dark-color" style="z-index: 1;">
                <b-row>
                    <b-col cols="6">
                        <span class="pre-title animate__animated " data-anime="animate__fadeInDown" style="color: #E4D7CF;">Предлагаем</span>
                    </b-col>
                    <b-col cols="6"></b-col>
                </b-row>
                <b-row>
                    <b-col class="wallet-block" md="6" lg="6">

                        <h2 class="title animate__animated " data-anime="animate__fadeInUp">
                            2 инструмента  для преумножения  ваших инвестиций
                        </h2>

                        <div class="wallet animate__animated " data-anime="animate__fadeInUp"></div>

                    </b-col>
                    <b-col md="6" lg="6">
                        <p class="list-header animate__animated " data-anime="animate__fadeInDown">Аренда хешрейта</p>
                        <ul>
                            <li class="animate__animated " data-anime="animate__fadeInRight" data-delay="100">Возможность начать майнить с любой суммы</li>
                            <li class="animate__animated " data-anime="animate__fadeInRight" data-delay="200">Сервисное обслуживание включено в контракт</li>
                            <li class="animate__animated " data-anime="animate__fadeInRight" data-delay="300">После завершения контракта оборудование остается  в собственности Qubity</li>
                        </ul>
                        <b-button :to="{ name: 'user-contracts-rent' }" v-ripple class="b-w-o animate__animated " data-anime="animate__fadeInUp" data-delay="400">
                            Рассчитать прибыль
                        </b-button>
                        <hr>
                        <p class="list-header animate__animated" data-anime="animate__fadeInDown">Покупка майнера</p>
                        <ul>
                            <li class="animate__animated " data-anime="animate__fadeInRight" data-delay="50">Создаем бизнес «Под ключ» </li>
                            <li class="animate__animated " data-anime="animate__fadeInRight" data-delay="100">Ежедневно начисляем прибыль</li>
                            <li class="animate__animated " data-anime="animate__fadeInRight" data-delay="150">Гарантируем бесперебойный хешрейт 365 дней в году</li>
                            <li class="animate__animated " data-anime="animate__fadeInRight" data-delay="200">Поломки, ремонт неисправностей за наш счет</li>
                            <li class="animate__animated " data-anime="animate__fadeInRight" data-delay="250">Возможность вернуть майнер, сдать в Trade in</li>
                        </ul>
                        <b-button :to="{ name: 'user-miners-buy' }" v-ripple class="b-w-o animate__animated " data-anime="animate__fadeInUp" data-delay="300">
                            Рассчитать прибыль
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </showAt>
        
        <showAt breakpoint="mediumAndBelow">
            <div class="q-section-block bg-dark-color" >
                <b-row>
                    <b-col cols="8" md="7">
                        <span class="pre-title animate__animated " data-anime="animate__fadeInDown" style="color: #E4D7CF;">Предлагаем</span>
                        
                        <h2 class="title animate__animated " data-anime="animate__fadeInUp">
                            2 инструмента для преумножения ваших инвестиций
                        </h2>
                        
                    </b-col>
                    <b-col cols="4" md="5" class="wallet-block" style="flex-direction: column;">
                        <div class="wallet animate__animated " data-anime="animate__fadeInUp" style="align-self: center; margin-bottom: auto; height: 100%; background-position: center;"></div>
                    </b-col>
                </b-row>
                
                <p class="list-header animate__animated " data-anime="animate__fadeInDown">Аренда хешрейта</p>
                <ul>
                    <li class="animate__animated " data-anime="animate__fadeInUp" data-delay="100">Возможность начать майнить с любой суммы</li>
                    <li class="animate__animated " data-anime="animate__fadeInUp" data-delay="200">Сервисное обслуживание включено в контракт</li>
                    <li class="animate__animated " data-anime="animate__fadeInUp" data-delay="300">После завершения контракта оборудование остается  в собственности Qubity</li>
                </ul>
                <b-button :to="{ name: 'user-contracts-rent' }" v-ripple class="b-w-o animate__animated " data-anime="animate__fadeInUp" data-delay="400">
                    Рассчитать прибыль
                </b-button>
                <hr>
                <p class="list-header animate__animated" data-anime="animate__fadeInDown">Покупка майнера</p>
                <ul>
                    <li class="animate__animated " data-anime="animate__fadeInUp" data-delay="50">Создаем бизнес «Под ключ» </li>
                    <li class="animate__animated " data-anime="animate__fadeInUp" data-delay="100">Ежедневно начисляем прибыль</li>
                    <li class="animate__animated " data-anime="animate__fadeInUp" data-delay="150">Гарантируем бесперебойный хешрейт 365 дней в году</li>
                    <li class="animate__animated " data-anime="animate__fadeInUp" data-delay="200">Поломки, ремонт неисправностей за наш счет</li>
                    <li class="animate__animated " data-anime="animate__fadeInUp" data-delay="250">Возможность вернуть майнер, сдать в Trade in</li>
                </ul>
                <b-button :to="{ name: 'user-miners-buy' }" v-ripple class="b-w-o animate__animated " data-anime="animate__fadeInUp" data-delay="300">
                    Рассчитать прибыль
                </b-button>
                
            </div>
        </showAt>
    </div>
</template>

<script>

    export default {

        data() {
            return {}
        },
        methods: {

        },
        components: {

        },
        watch: {

        },
        mounted() {

        }

    }

</script>