<template>
    <div id="how-it-works">
        
        <!--<showAt breakpoint="large">-->
            <div class="q-section-block bg-yellow-color how-it-works" style="z-index: 2;">
                <b-row>
                    <b-col cols="6">
                        <span class="pre-title animate__animated " data-anime="animate__fadeInDown" data-delay="50" style="color: #1B57FF;">Объясняем</span>
                    </b-col>
                    <b-col cols="6"></b-col>
                </b-row>
                <b-row>
                    <b-col md="12" lg="6">
                        <h2 class="title dark animate__animated " data-anime="animate__fadeInUp" data-delay="150">
                            Как это работает?
                        </h2>
                        <p class="content dark animate__animated " data-anime="animate__fadeIn" data-delay="200" style="margin-bottom: 0;">
                            Мы занимаемся майнингом в промышленном масштабе на высокотехнологичном дорогостоящем оборудовании и предоставляем вычислительные мощности в аренду или к продаже нашим клиентам. Всю работу берем на себя мы, а клиенты получают прозрачные выплаты в BTC на кошелек личного кабинета, которые можно вывести в любой момент. 
                        </p>
                        <!-- <b-button @click="showModal('callback')" v-ripple class="b-w-f animate__animated " data-anime="animate__fadeInUp" data-delay="500" style="margin-top: 0px;">
                            Начать майнить
                        </b-button> -->
                         <b-button  :to="{ name: 'auth-register'}" v-ripple class="b-w-f animate__animated " data-anime="animate__fadeInUp" data-delay="500" style="margin-top: 0px;">
                            Начать майнить
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        <!--</showAt>--> 
<!--        <showAt breakpoint="medium">
            <div class="q-section-block bg-yellow-color how-it-works" style="z-index: 2;">
                <b-row>
                    <b-col cols="6">
                        <span class="pre-title animate__animated " data-anime="animate__fadeInDown" data-delay="50" style="color: #1B57FF;">Объясняем</span>
                    </b-col>
                    <b-col cols="6"></b-col>
                </b-row>
                <b-row>
                    <b-col md="6" lg="6">
                        <h1 class="title dark animate__animated " data-anime="animate__fadeInUp" data-delay="150">
                            Как это работает?
                        </h1>
                        <p class="content dark animate__animated " data-anime="animate__fadeIn" data-delay="200" style="margin-bottom: 0;">
                            Мы занимаемся майнингом в промышленном масштабе на высокотехнологичном дорогостоящем оборудовании и предоставляем вычислительные мощности в аренду нашим клиентам. Всю работу берем на себя мы, а клиенты просто получают BTC на кошелек личного кабинета, которые можно вывести в любой момент.
                        </p>
                        <b-button v-ripple class="b-w-f animate__animated " data-anime="animate__fadeInUp" data-delay="500" style="margin-top: 0px;">
                            Начать майнить
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </showAt>-->
        
    </div>
</template>

<script>

    export default {

        data() {
            return {
            }
        },
        methods: {
            showModal(type){
                this.$parent.showModal(type);
            },
        },
        components: {

        },
        watch: {

        },
        mounted() {

        }

    }

</script>