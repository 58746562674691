<template>
    <div id="futures">
        <!--<showAt breakpoint="large">-->
            <div class="q-section-block bg-gray-light-color" style="z-index: 3;">
                <b-row>
                    <b-col cols="6">
                        <span class="pre-title animate__animated " data-anime="animate__fadeInDown" data-delay="100" style="color: #3557F6;">Пользуйся</span>
                    </b-col>
                    <b-col cols="6">

                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="12" lg="6">
                        <h2 class="title dark animate__animated " data-anime="animate__fadeInUp" data-delay="200" >
                            Преимущества <br>нашей платформы
                        </h2>
                        <p class="content dark animate__animated " data-anime="animate__fadeIn" data-delay="300"  style="margin-bottom: 0;">
                            Платформа для майнинга «Qubity» предоставляет мониторинг и аналитику Ваших контрактов в личном кабинете.                        
                        </p>
                    </b-col>
                    <b-col md="12" lg="6" class="animate__animated overflow-hidden"  data-anime="animate__fadeInUp" data-delay="200">
                        <showAt breakpoint='large'>
                             <b-img fluid src="~@/assets/images/site/futures_desktop.png" style="transform: scale(1.15, 1.15) translateY(-25px);" />
                        </showAt>
                        <showAt breakpoint='mediumAndBelow'>
                             <b-img fluid src="~@/assets/images/site/futures_desktop.png" />
                        </showAt>
                    </b-col>
                </b-row>
                <hr class="light" style="margin: 0;">
                <div class="futures bg-gray-secondary-color" style="padding-top: 32px;">
                    <b-row>
                        <b-col md="6" lg="4" class="f-block">
                            <div class="f-block-icon animate__animated animate__slow" data-anime="animate__pulse" data-delay="0">
                                <b-img fluid src="~@/assets/images/site/icons/btc_rocket.png" />
                            </div>
                            <div class="f-block-content animate__animated"  data-anime="animate__fadeInUp" data-delay="0">
                                <span>Создаем майнинг-бизнес<br>«под ключ» и управляем<br>им за Вас</span>
                            </div>
                        </b-col>
                        <b-col md="6" lg="4" class="f-block">
                            <div class="f-block-icon animate__animated animate__slow" data-anime="animate__pulse" data-delay="100">
                                <b-img fluid src="~@/assets/images/site/icons/btc_calendar.png" />
                            </div>
                            <div class="f-block-content animate__animated"  data-anime="animate__fadeInUp" data-delay="100">
                                <span>365 дней в году гарантируем бесперебойный хэшрейт<br>24/7</span>
                            </div>
                        </b-col>
                        <b-col md="6" lg="4" class="f-block">
                            <div class="f-block-icon animate__animated animate__slow" data-anime="animate__pulse" data-delay="200">
                                <b-img fluid src="~@/assets/images/site/icons/btc_hands.png" />
                            </div>
                            <div class="f-block-content animate__animated"  data-anime="animate__fadeInUp" data-delay="200">
                                <span>100% компенсируем недополученной прибыли  во время какого-либо простоя</span>
                            </div>
                        </b-col>
                   
                        <b-col md="6" lg="4" class="f-block">
                            <div class="f-block-icon animate__animated animate__slow" data-anime="animate__bounceIn" data-delay="100">
                                <b-img fluid src="~@/assets/images/site/icons/btc_grow.png" />
                            </div>
                            <div class="f-block-content animate__animated" data-anime="animate__fadeInUp" data-delay="100">
                                <span>Ежедневно начисляем прибыль  и списываем плату за обслуживание</span>
                            </div>
                        </b-col>
                        <b-col md="6" lg="4" class="f-block">
                            <div class="f-block-icon animate__animated animate__slow" data-anime="animate__bounceIn" data-delay="200">
                                <b-img fluid src="~@/assets/images/site/icons/btc_service.png" />
                            </div>
                            <div class="f-block-content animate__animated" data-anime="animate__fadeInUp" data-delay="200">
                                <span>Поломки, ремонт неисправностей  и обслуживание за наш счет</span>
                            </div>
                        </b-col>
                        <b-col md="6" lg="4" class="f-block">
                            <div class="f-block-icon animate__animated animate__slow" data-anime="animate__bounceIn" data-delay="200">
                                <b-img fluid src="~@/assets/images/site/icons/btc_refresh.png"  />
                            </div>
                            <div class="f-block-content animate__animated" data-anime="animate__fadeInUp" data-delay="200">
                                <span>Возможность сдать в trade-in  и сделать upgrade в любой момент</span>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </div>
        <!--</showAt>-->
    </div>
</template>

<script>

    export default {

        data() {
            return {}
        },
        methods: {

        },
        components: {

        },
        watch: {

        },
        mounted() {

        }

    }

</script>