<template>
    <div>
        <!--<showAt breakpoint="large">-->
             <div class="q-section-block bg-gray-color q-join-block" style="z-index: 3; padding-bottom: 40px;">
                <b-row>
                    <b-col cols="6">
                        <span class="pre-title animate__animated" data-anime="animate__fadeInDown" data-delay="100" style="color: #3557F6;">Присоединяйся</span>
                    </b-col>
                    <b-col cols="6"></b-col>
                </b-row>
                <b-row>
                    <b-col md="12" lg="7">
                        <h2 class="title dark animate__animated" data-anime="animate__fadeInUp" data-delay="200">
                            Почему заходить <br>  нужно сейчас?
                        </h2>
                        <ul class="dark" style="margin-top: 32px;">
                            <li class="animate__animated" data-anime="animate__fadeInRight" data-delay="100">За 10 лет курс биткоина вырос более чем в 50.000 раз</li>
                            <li class="animate__animated" data-anime="animate__fadeInRight" data-delay="200">За последние 5 лет — более чем в 100 раз</li>
                            <li class="animate__animated" data-anime="animate__fadeInRight" data-delay="300">С каждым годом появляется все больше криптосторонников: начиная от частных инвесторов, заканчивая институциональными инвесторами,<br>такими как как MicroStrategy и Tesla</li>
                        </ul>

                        <div class="q-bubble">
                            <div class="animate__animated animate__slow" data-anime="animate__bounceIn" data-delay="100">
                                <b-img fluid src="~@/assets/images/site/bubble/q-author-photo-1.jpeg" style="min-width: 64px; height: 64px; border-radius: 64px;"  />
                            </div>
                            <div style="margin-left: 0px; margin-bottom: 11px;">
                                <b-img fluid src="~@/assets/images/site/bubble/q-bubble-text.svg" class="animate__animated animate__slower" data-anime="animate__bounceIn" data-delay="100" />
                                <div class="q-author" style="margin-left: 22px; margin-top: 10px;">
                                    <p class="mb-0 animate__animated" data-anime="animate__fadeInUp" data-delay="200" style="font-weight: 400; font-size: 16px; line-height: 16px; letter-spacing: 0.06px; color: #000;">Арсений Шульгин</p>
                                    <span class="animate__animated" data-anime="animate__fadeInUp" data-delay="300" style="font-weight: 300; letter-spacing: 0.06px; font-size: 12px; line-height: 14px; color: rgba(0,0,0,0.5)">CEO Qubity</span>
                                </div>
                            </div>
                        </div>

                        <div style="margin-top: 64px;">
                            <!-- <b-button @click="showModal('callback')" class="b-w-f animate__animated" data-anime="animate__fadeInLeft" data-delay="100" style="margin-right: 10px;">Начать майнить</b-button> -->

                            <b-button :to="{ name: 'auth-register'}" class="b-w-f animate__animated" data-anime="animate__fadeInLeft" data-delay="100" style="margin-right: 10px;">Начать майнить</b-button>

                        </div>
                    </b-col>
                </b-row>
            </div>   
        <!--</showAt>-->
    </div>
</template>

<script>

    export default {

        data() {
            return {
            }
        },
        methods: {
            showModal(type){
                this.$parent.showModal(type);
            },
        },
        components: {

        },
        watch: {

        },
        mounted() {

        }

    }

</script>