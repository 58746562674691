<template>
    <div>
        <showAt breakpoint="large">
            <div class="q-hero">
                <div class="q-welcome">
                    <h2 class="q-title animate__animated" data-anime="animate__fadeInUp" data-delay="300">Платформа <br>для майнинга</h2>
                    <p class="q-description animate__animated" data-anime="animate__fadeInDown" data-delay="400">
                        Купите или арендуйте мощность и зарабатывайте<br>до 150% годовых на майнинге криптовалют
                    </p>
                    <!-- <b-button @click="showModal('callback')" v-ripple class="q-button animate__animated" data-anime="animate__fadeInUp" data-delay="500">
                        Начать майнить
                    </b-button> -->
                    <b-button :to="{ name: 'auth-register'}" v-ripple class="q-button animate__animated" data-anime="animate__fadeInUp" data-delay="500">
                        Начать майнить
                    </b-button>
                </div>
                <div class="q-image" >

                    <div class="animate__animated" data-name="header" data-anime="animate__jackInTheBox" style="top: 10%; left: 0;" >
                        <b-img fluid src="~@/assets/images/site/hero/coins_1.png"  />
                    </div> 
                    <div class="animate__animated" data-name="header" data-anime="animate__fadeInUp" style="bottom: -10%; left: -10%;" >
                        <b-img fluid src="~@/assets/images/site/hero/coins_2.png"   />
                    </div>
                    <div class="animate__animated" data-name="header" data-anime="animate__fadeInDown"  >
                        <b-img fluid src="~@/assets/images/site/hero/videocard.png" /> 
                    </div>
                    <div class="animate__animated animate__slow animate__infinite" data-name="header" data-anime="animate__tada" style="bottom: 0; right: 0;" >
                        <b-img fluid src="~@/assets/images/site/hero/coins_3.png" style="transform: scale(.65,.65);"  />
                    </div>

                </div>
            </div>
        </showAt>
        <showAt breakpoint="medium">
            <div class="q-hero">
                <div class="q-welcome">
                    <h2 class="q-title animate__animated" data-anime="animate__fadeInUp" data-delay="300">Платформа для майнинга</h2>
                    <p class="q-description animate__animated" data-anime="animate__fadeInDown" data-delay="400">
                        Купите или арендуйте мощность и зарабатывайте<br>до 150% годовых на майнинге криптовалют
                    </p>
                    <!-- <b-button @click="showModal('callback')" class="q-button animate__animated" data-anime="animate__fadeInUp" data-delay="500">
                        Начать майнить
                    </b-button> -->
                    <b-button  :to="{ name: 'auth-register'}" class="q-button animate__animated" data-anime="animate__fadeInUp" data-delay="500">
                        Начать майнить
                    </b-button>
                </div>
                <div class="q-image" >

                    <div class="animate__animated" data-name="header" data-anime="animate__jackInTheBox" style="left: 0%; bottom: 0%;">
                        <b-img fluid src="~@/assets/images/site/hero/coins_1.png"  />
                    </div> 
                    <div class="animate__animated" data-name="header" data-anime="animate__fadeInUp"  style="left: 0; bottom: -10%;">
                        <b-img fluid src="~@/assets/images/site/hero/coins_2.png"   />
                    </div>
                    <div class="animate__animated" data-name="header" data-anime="animate__fadeInUp" style="left: -10%; bottom: 0; right: 0;" >
                        <b-img  src="~@/assets/images/site/hero/videocard.png" style="transform: scale(1,1); "  /> 
                    </div>
                    <div class="animate__animated animate__slow animate__infinite" data-name="header" data-anime="animate__tada" style="right: -20%; bottom: 0;">
                        <b-img fluid src="~@/assets/images/site/hero/coins_3.png"  />
                    </div>

                </div>
            </div>
            
        </showAt>
        <showAt breakpoint="small">
            <div class="q-hero">
                <div class="q-welcome">
                    <h2 class="q-title " data-anime="animate__fadeInUp" >Платформа для майнинга</h2>
                    <p class="q-description " data-anime="animate__fadeInDown" >
                        Купите или арендуйте мощность и зарабатывайте до 150% годовых на майнинге криптовалют
                    </p>
                    <!-- <b-button @click="showModal('callback')" class="q-button " data-anime="animate__fadeInUp" >
                        Начать майнить
                    </b-button> -->
                    <b-button :to="{ name: 'auth-register'}" class="q-button " data-anime="animate__fadeInUp" >
                        Начать майнить
                    </b-button>
                </div>
                <div class="q-image" style="margin-top: -86px;">

                    <div class="animate__animated" data-name="header" data-anime="animate__jackInTheBox" style="left: -100%; bottom: -30%;">
                        <b-img src="~@/assets/images/site/hero/coins_1.png" style="transform: scale(.45,.45);" />
                    </div> 
                    <div class="animate__animated" data-name="header" data-anime="animate__fadeInUp"  style="left: -120%; bottom: -45%;">
                        <b-img src="~@/assets/images/site/hero/coins_2.png"  style="transform: scale(.45,.45); " />
                    </div>
                    <div class="animate__animated" data-name="header" data-anime="animate__fadeInUp" style="left: -80%; bottom: -25%;" >
                        <b-img src="~@/assets/images/site/hero/videocard.png" style="transform: scale(.5,.5); " /> 
                    </div>
                    <div class="animate__animated animate__slow animate__infinite" data-name="header" data-anime="animate__tada" style="left: -20%; bottom: -25%;">
                        <b-img src="~@/assets/images/site/hero/coins_3.png" style="transform: scale(.35,.35); "  />
                    </div>

                </div>
            </div>
            
        </showAt>

        <h1 class="visually-hidden">Платформа для майнинга криптовалют</h1>
        <p class="visually-hidden">Глагол «mining» появился в английском языке давно – изначально так называли процесс добычи полезных ископаемых. В XXI веке это слово прочно закрепилось в русском языке, правда, с новым, рождённым в эпоху цифровых технологий смыслом. Сейчас оно означает особый род деятельности, который способен принести значительный доход. Предлагаем разобраться подробнее, что такое майнинг криптовалюты и как на нём зарабатывать.</p>
        <h2 class="visually-hidden">Майнинг – что это?</h2>
        <p class="visually-hidden">Майнингом называют процесс создания виртуальных денег – цифровых валют, например, биткоин, лайткоин, эфириум, специфика которых состоит в отсутствии централизованного регулирования эмиссии. По сути, это процесс регистрации и проверки транзакций в децентрализованной системе.</p>
        <p class="visually-hidden">Функции майнеров состоят в хранении копий блокчейна, которые необходимы для защиты данных о сделках от потерь и подделок. Кроме того, специалисты, занятые майнингом, проводят подтверждение и проверки транзакций, то есть операций, совершенных с криптовалютой. Можно сказать, что майнинг криптовалюты предполагает решение криптографических уравнений, которое ведётся с помощью профессионального оборудования.</p>
        <p class="visually-hidden">Инструменты для майнинга можно приобрести в личное использование или взять в аренду у специализированных компаний. В зависимости от того, кому принадлежит оборудование и кто несёт ответственность за его работоспособность, выделяют несколько способов добычи «цифрового золота».</p>
        <h2 class="visually-hidden">Способы майнинга криптовалют</h2>
        <p class="visually-hidden">Генерацию цифровых активов можно организовать тремя способами.</p>
        <ul class="visually-hidden">
            <li class="visually-hidden">Соло-майнинг – это такой тип майнинга, когда решение криптографических задач ведётся в одиночку. Для этого создаётся домашняя ферма, то есть специальный компьютер высокой мощности. Чтобы майнить индивидуально, необходимы специфические видеокарты. Многие аналитики уверены, что эпоха добычи криптовалюты на собственных компьютерах прошла. Они аргументируют свою позицию тем, что уровень сложности генерации цифровых богатств растёт с каждым днём, а вместе с ним повышаются требования к программному обеспечению и минимумам потребляемой электроэнергии. Кроме того, соло-майнинг требует значительных стартовых вложений, не менее нескольких тысяч долларов.</li>
            <li class="visually-hidden">Майнинг в пулах – принцип добычи цифровых монет, предполагающий распределение функций по расчёту подписи блока между множеством майнеров, подключённых к одному серверу. Пулы способны подбирать блоки транзакций, за которые предполагается вознаграждение, гораздо быстрее, нежели соло-игроки. Правда, доходом от майнинга криптовалют в пулах также приходится делиться со всеми участниками сообщества.</li>
            <li class="visually-hidden">Облачный майнинг криптовалют – это способ выработки цифровых валют с помощью вычислительных мощностей, которые арендуются у крупных специализированных сервисов. Настройку оборудования для добычи токенов берут на себя дата-центры, эксперты которых досконально знакомы с тонкостями работы. В последние годы востребованность платформ для майнинга стремительно растёт, ведь работа с ними не требует покупки и настройки дорогостоящей техники. Это наиболее простой способ работы с цифровыми богатствами, доступный людям без специальных знаний.</li>
        </ul>
        <h3 class="visually-hidden">Выбор платформы для майнинга криптовалют</h3>
        <p class="visually-hidden">Итак, наибольшую популярность сегодня имеет облачный майнинг криптовалют. Это возможность зарабатывать на «виртуальном золоте» за счёт аренды хешрейта, то есть вычислительной мощности.</p>
        <p class="visually-hidden">Залог успеха тех, кто сделал ставку на виртуальный майнинг, состоит в выборе надёжного партнёра – сайта облачной добычи криптовалюты. Стоимость биткоинов и альткоинов обновляет максимумы, поэтому интерес к ним с каждым днём проявляет всё больше людей. Спрос рождает предложение – услуги облачного майнинга криптовалют сейчас предлагает множество различных компаний. Правда, как и во многих других сферах, за яркой рекламой могут скрываться фирмы-однодневки, сотрудничество с которыми приводит к финансовым потерям. Именно поэтому к выбору платформы для майнинга криптовалют нужно подойти особенно внимательно.</p>
        <p class="visually-hidden">Начинать работу лучше с проверки потенциальных контрагентов на соответствие нескольким критериям.</p>
        <dl class="visually-hidden">
            <dt>Открытость платформы для майнинга.</dt> <dd>Первое, на что стоит обратить внимание, – насколько полная информация доступна о потенциальном партнёре. Например, есть ли на его сайте данные о физическом местонахождении компании, представлен ли номер телефона, чаты для оперативной консультации и т. п. Не лишним будет связаться с представителями платформы и обсудить детали её работы с клиентами. Общаясь со специалистами, стоит проанализировать уровень их открытости и те нормы общения, которых они придерживаются.</dd>
            <dt>Удобство майнинга криптовалют на площадке.</dt> <dd>Очень важно оценить, насколько комфортно майнить на том или ином сервисе. Значение имеет выбор валют, с которыми можно работать, тарифный план, условия вывода капитала, доступность консультаций.</dd>
            <dt>Репутация сервиса.</dt> <dd>В интернете достаточно много информации об облачном майнинге криптовалют: сейчас легко отыскать топы наиболее надёжных компаний и антирейтинги, составленные на основе негативных отзывов пользователей. Кроме того, многие майнеры охотно делятся опытом работы на различных форумах.</dd>
        </dl>
        <p class="visually-hidden">Итак, добыча цифровых валют с использованием облачных площадок – достаточно перспективный вид заработка на 2022 год. В долгосрочной перспективе интерес к ней будет лишь расти, ведь стоимость биткоина и других валют неудержимо увеличивается. Главное – выбрать надёжную платформу для майнинга, работа на которой будет максимально комфортной и простой и обеспечит желаемый уровень благосостояния.</p>

    </div>
</template>

<script>

    export default {

        data() {
            return {
            }
        },
        methods: {
            showModal(type){
                this.$parent.showModal(type);
            },
        },
        components: {
        },
        watch: {

        },
        mounted() {
           
            
        }

    }

</script>