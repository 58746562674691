<template>
    <div class="q-section-block bg-white-color faq-block" style="margin-top: 96px;">
        <h2 class="animate__animated" data-anime="animate__fadeIn">
            Часто задаваемые<br>вопросы
        </h2>
        <div class="faq-section animate__animated" data-anime="animate__fadeInUp" data-delay="100">

            <div class="faq-section-header" v-b-toggle.faq-1 >
                <p>Какие конкурентные преимущества у вас есть?</p>
                <showAt breakpoint="mediumAndAbove">
                    <b-icon icon="plus-circle" aria-hidden="true" style="width: 32px; height: 32px;"></b-icon>
                </showAt>
            </div>

            <b-collapse id="faq-1" class="faq-section-content"  role="tabpanel">
                <p>
                    Мы можем долго перечислять наши сильные стороны, но сейчас выделим лишь основные:
                </p>
                <ul>
                    <li>
                        Большой ассортимент оборудования в наличии;
                    </li>
                    <li>
                        Исключительно сертифицированное оборудование;
                    </li>
                    <li>
                        Гарантия бесперебойного хешрейта, а также ремонт и обслуживание включены в стоимость;
                    </li>
                    <li>
                        Собственные дата-центры;
                    </li>
                    <li>
                        Прозрачные ежедневные выплаты в удобном личном кабинете
                    </li>
                    <li>
                        Возможность вернуть майнер досрочно, сдать в trade-in и сделать upgrade в любой момент;
                    </li>
                    <li>
                        Разные инструменты для инвестиций: от покупки майнера до аренды хешрейта;
                    </li>
                </ul>
            </b-collapse>

        </div>
        <div class="faq-section animate__animated" data-anime="animate__fadeInUp" data-delay="200">

            <div class="faq-section-header" v-b-toggle.faq-2 >
                <p>Какие есть риски?</p>
                <showAt breakpoint="mediumAndAbove">
                    <b-icon icon="plus-circle" aria-hidden="true" style="width: 32px; height: 32px;"></b-icon>
                </showAt>
            </div>

            <b-collapse id="faq-2" class="faq-section-content" role="tabpanel">
                <p>
                    Безусловно, в нашей сфере, как и в любой другой, тоже есть риски. Могут случится разного рода форс-мажоры, которые отрицательно скажутся на курсе криптовалюты. Но мы предпочитаем отталкиваться от фактов и, на данный момент, факты говорят о том, что криптовалюта и майнинг уже твердо закрепились на рынке и будут лишь развиваться. А мы, как эксперты с внушительным опытом, сделаем все, чтобы обезопасить вас от ошибок и помочь вам заработать.
                </p>
            </b-collapse>

        </div>
        <div class="faq-section animate__animated" data-anime="animate__fadeInUp" data-delay="300">

            <div class="faq-section-header" v-b-toggle.faq-3 >
                <p>Мне нравится идея, с чего начать?</p>
                <showAt breakpoint="mediumAndAbove">
                    <b-icon icon="plus-circle" aria-hidden="true" style="width: 32px; height: 32px;"></b-icon>
                </showAt>
            </div>

            <b-collapse id="faq-3" class="faq-section-content" role="tabpanel">
                <p>
                    Начать очень просто! Вы можете оставить заявку у нас на сайте и специалист нашей компании свяжется с Вами, чтобы познакомиться и исчерпывающе ответить на все вопросы, а далее уже приступить к заключению договора.
                </p>
            </b-collapse>

        </div>
        <div class="faq-section animate__animated" data-anime="animate__fadeInUp" data-delay="400">

            <div class="faq-section-header" v-b-toggle.faq-4 >
                <p>Оказываете ли вы поддержку своим клиентам (партнёрам)?</p>
                <showAt breakpoint="mediumAndAbove">
                    <b-icon icon="plus-circle" aria-hidden="true" style="width: 32px; height: 32px;"></b-icon>
                </showAt>
            </div>

            <b-collapse id="faq-4" class="faq-section-content" role="tabpanel">
                <p>
                    Мы считаем, что успех наших клиентов — так же и наш успех! Именно поэтому, мы щепетильно относимся к сопровождению партнеров и готовы оперативно отвечать на все возникающие вопросы!
                </p>
            </b-collapse>

        </div>
        <div class="faq-section animate__animated" data-anime="animate__fadeInUp" data-delay="400">

            <div class="faq-section-header" v-b-toggle.faq-5 >
                <p>Я Вам не доверяю</p>
                <showAt breakpoint="mediumAndAbove">
                    <b-icon icon="plus-circle" aria-hidden="true" style="width: 32px; height: 32px;"></b-icon>
                </showAt>
            </div>

            <b-collapse id="faq-5" class="faq-section-content" role="tabpanel">
                <p>
                    Недоверие — это нормально. <br>
                    Мы не можем заставить Вас поверить нам, но готовы предоставить любую информацию, которая поможет Вам убедиться в том, что мы абсолютно прозрачны и добросовестно ведем свою деятельность. 
                </p>
            </b-collapse>

        </div>
    </div>
</template>

<script>

    export default {

        data() {
            return {}
        },
        methods: {

        },
        components: {

        },
        watch: {

        },
        mounted() {

        }

    }

</script>